import React, { Component } from "react";
import Page from "../components/Page";
import Space from "../components/elements/Space/Space";
import Layout from "../components/Layout";

export default class PrivacyPolicyPage extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.id = "CookieDeclaration";
    script.src = "https://consent.cookiebot.com/1606728e-16d6-4b6f-bbf2-8e8b2ef8de81/cd.js";
    script.async = true;
    document.getElementById("cookies").appendChild(script);
  }

  render() {
    return (
      <Page title='Privacy policy' description='' image='/favicon.png'>
        <Layout>
          {/* navigation space */}
          <Space mobile={15} table={15} desktop={15} />

          <main class='main-content'>
            <section class='section '>
              <div class='container'>
                <div class='row justify-content-center'>
                  <div class='col-md-10'>
                    <div class='row text-center'>
                      <div class='col-md-12'>
                        <h2>INFORMATIVA SUL TRATTAMENTO DEI DATI PERSONALI AI SENSI DELL’ARTICOLO 13 DEL REGOLAMENTO UE 2016/679</h2>
                      </div>
                    </div>
                    <div class='row mt-8'>
                      <div class='col-md-6  text-center'>
                        <h4>TITOLARE</h4>
                      </div>
                      <div class='col-md-6 '>
                        <p class=''>E-leads s.r.l.</p>
                        <a class='mt-2' href='#Titolare'>
                          + informazioni
                        </a>
                      </div>
                    </div>

                    <div class='row mt-3'>
                      <div class='col-md-6  text-center'>
                        <h4>FINALITÀ</h4>
                      </div>
                      <div class='col-md-6 '>
                        <ol style={{ paddingLeft: 0 }}>
                          <li>Contrattuale o di esecuzione di misure precontrattuali;</li>
                          <li>Marketing diretto;</li>
                          <li>Marketing indiretto;</li>
                          <li>Analisi del traffico durante la navigazione sul sito;</li>
                          <li>Rilevazione del livello di soddisfazione della clientela e miglioramento del sito;</li>
                        </ol>
                        <a class='mt-2' href='#Finalita'>
                          + informazioni
                        </a>
                      </div>
                    </div>

                    <div class='row mt-3'>
                      <div class='col-md-6  text-center'>
                        <h4>BASE GIURIDICA</h4>
                      </div>
                      <div class='col-md-6 '>
                        <ol style={{ paddingLeft: 0 }}>
                          <li>Esecuzione di un contratto o di misure precontrattuali;</li>
                          <li>Consenso;</li>
                          <li>Legittimo interesse;</li>
                        </ol>
                        <a class='mt-2' href='#Base-giuridica'>
                          + informazioni
                        </a>
                      </div>
                    </div>

                    <div class='row mt-3'>
                      <div class='col-md-6  text-center'>
                        <h4>DESTINATARI O CATEGORIE DI DESTINATARI</h4>
                      </div>
                      <div class='col-md-6 '>
                        <ol style={{ paddingLeft: 0 }}>
                          <li>Fornitori di servizi di hosting;</li>
                          <li>Fornitori di servizi di analisi statistica, analisi del traffico web, marketing e pubblicità;</li>
                          <li>Soggetti terzi indicati nell’informativa estesa;</li>
                        </ol>
                        <a class='mt-2' href='#Destinatari'>
                          + informazioni
                        </a>
                      </div>
                    </div>

                    <div class='row mt-3'>
                      <div class='col-md-6  text-center'>
                        <h4>CATEGORIE DI DATI PERSONALI TRATTATI</h4>
                      </div>
                      <div class='col-md-6 '>
                        <ol style={{ paddingLeft: 0 }}>
                          <li>Dati comuni raccolti in automatico dal sito;</li>
                          <li>Dati comuni raccolti tramite i form del sito;</li>
                        </ol>
                        <a class='mt-2' href='#Categorie'>
                          + informazioni
                        </a>
                      </div>
                    </div>

                    <div class='row mt-3'>
                      <div class='col-md-6  text-center'>
                        <h4>TRASFERIMENTO DEI DATI IN PAESI TERZI</h4>
                      </div>
                      <div class='col-md-6 '>
                        <p class=''>Stati Uniti</p>
                        <a class='mt-2' href='#Trasferimento'>
                          + informazioni
                        </a>
                      </div>
                    </div>

                    <div class='row mt-3'>
                      <div class='col-md-6  text-center'>
                        <h4>CRITERI PER LA CONSERVAZIONE DEI DATI</h4>
                      </div>
                      <div class='col-md-6 '>
                        <p class=''>Criteri utilizzati per stabilire il periodo di conservazione dei dati.</p>
                        <a class='mt-2' href='#Conservazione'>
                          + informazioni
                        </a>
                      </div>
                    </div>

                    <div class='row mt-3'>
                      <div class='col-md-6  text-center'>
                        <h4>DIRITTI DELL’INTERESSATO</h4>
                      </div>
                      <div class='col-md-6 '>
                        <p class=''>Accesso, rettifica, cancellazione dei dati ed altri diritti indicati nel dettaglio nell’Informativa estesa e previsti dal Regolamento UE 2016/679.</p>
                        <a class='mt-2' href='#Diritti'>
                          + informazioni
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class='row justify-content-center mt-8'>
                  <div class='col-md-10'>
                    <div class='row text-center'>
                      <div class='col-md-12'>
                        <h2>INFORMATIVA ESTESA</h2>
                      </div>
                    </div>

                    <div class='row mt-8' id='Titolare'>
                      <div class='col-md-12'>
                        <h4>IDENTITÀ E DATI DI CONTATTO DEL TITOLARE</h4>
                        <p class=' mt-6'>
                          <b>Identità</b>: E-leads s.r.l.
                          <br />
                          <b>Indirizzo</b>: SS Trossi, 41, 13871 Verrone (Bi)
                          <br />
                          <b>Indirizzo di posta elettronica</b>: info@e-leads.it
                          <br />
                        </p>
                      </div>
                    </div>

                    <div class='row mt-3' id='Finalita'>
                      <div class='col-md-12'>
                        <h4>FINALITÀ DEL TRATTAMENTO</h4>
                        <p class=' mt-6'>
                          <ol>
                            <li>
                              <b>Finalità contrattuale o di esecuzione di misure precontrattuali</b>
                              <br />
                              Tratteremo i tuoi dati personali per l’esecuzione di misure precontrattuali, contrattuali e di esecuzione del servizio. Il trattamento dei dati sarà quindi necessario per
                              fornirti informazioni utili alla stipula del contratto di servizi, all’uso della piattaforma digitale https://app.e-leads.it in conformità ai termini d’uso e nei limiti
                              di quanto necessario all’erogazione del servizio richiesto. I dati personali verranno raccolti attraverso i moduli di contatto e registrazione disponibili sul sito web.
                              In particolare, i dati personali saranno trattati al fine di:
                              <ol>
                                <li>Consentirti di utilizzare il sito web ed i servizi;</li>
                                <li>Permetterti di contattarci al fine di ricevere informazioni sui nostri servizi e sui contratti da stipulare</li>
                                <li>Fornirti risposte ed informazioni sulle caratteristiche del sito e della piattaforma</li>
                              </ol>
                            </li>
                            <li>
                              <b>Finalità di marketing diretto anche attraverso attività di segmentazione dell’utenza</b>
                              <br />
                              Qualora non sia possibile avvalerci del legittimo interesse quale base giuridica del trattamento, chiederemo il tuo consenso all’elaborazione dei dati per finalità di
                              marketing diretto ed in particolare per l’invio di materiale pubblicitario, vendita diretta, compimento di ricerche di mercato e comunicazione commerciale, con sistemi
                              automatizzati quali e-mail, telefono, sms, mms. Al fine di programmare un’adeguata offerta dei nostri servizi potremo utilizzare strumenti automatizzati che permettono di
                              segmentare l’utenza in base all’analisi dei dati di traffico, in modo da inviare pubblicità in linea con le preferenze manifestate dai visitatori durante la navigazione
                              online. Il conferimento dei dati per finalità di marketing è facoltativo ed il mancato conferimento non comporterà alcuna conseguenza sulla prestazione del servizio.
                            </li>
                            <li>
                              <b>Finalità di analisi del traffico e del comportamento degli utenti durante la navigazione sul sito</b>
                              <br />
                              Ci riserviamo di monitorare l’uso del sito da parte dei visitatori, registrare informazioni quali i movimenti ed i click del mouse, le attività di scorrimento della
                              pagina, informazioni su browser (tipo, versione, dimensioni dello schermo, ecc.), informazioni di base sull’utente (paese, lingua, fuso orario), per migliorare la qualità
                              del sito e dei servizi
                            </li>
                            <li>
                              <b>Finalità di verifica del livello di soddisfazione della clientela e miglioramento del sito.</b>
                              <br />
                              Potremo contattarti per rilevare il tuo grado di soddisfazione in relazione al servizio reso dal Titolare e/o da società Partner. Potremo inoltre utilizzare interviste
                              online per rilevare il grado di soddisfazione nell’uso del sito, raccogliendo i feedback dei visitatori. Per proteggere la tua privacy, le informazioni che inserisci nei
                              moduli online sono rese anonime.
                            </li>
                          </ol>
                        </p>
                      </div>
                    </div>

                    <div class='row mt-3' id='Base-giuridica'>
                      <div class='col-md-12'>
                        <h4>BASE GIURIDICA DEL TRATTAMENTO</h4>
                        <p class=' mt-6'>
                          <ol>
                            <li>
                              <b>
                                Trattamento dei dati necessario all'esecuzione di un contratto di cui l’interessato è parte o all’esecuzione di misure precontrattuali adottate su richiesta dello
                                stesso, compresa la prestazione di un servizio.
                              </b>
                              <br />
                              Ai sensi del Regolamento UE 2016/679, il trattamento da noi effettuato deve sempre avere una base giuridica. Il trattamento dei Tuoi dati è necessario all'esecuzione di
                              un contratto di cui sei parte, all’esecuzione di misure precontrattuali adottate su tua richiesta, alla prestazione di un servizio nell’ambito di un rapporto contrattuale
                              e per rispondere ad una tua richiesta.
                              <br />I tuoi dati personali saranno pertanto conservati per il tempo strettamente necessario alla prestazione dei servizi e successivamente cancellati, come meglio
                              precisato nel capitolo “Criteri per la conservazione dei dati”. Il mancato conferimento comporterà l’impossibilità da parte nostra di prestare i servizi richiesti e
                              rispondere alle tue richieste.
                            </li>
                            <li>
                              <b>Consenso</b>
                              <br />
                              Qualora non sia possibile avvalerci del legittimo interesse, chiederemo il tuo consenso per trattare i tuoi dati personali per finalità di marketing diretto ed in
                              particolare per l’invio di materiale pubblicitario, vendita diretta, compimento di ricerche di mercato e comunicazione commerciale, anche attraverso l’uso sistemi
                              automatizzati di remarketing finalizzati ad inviarti comunicazioni in linea con le preferenze da te manifestate durante la navigazione online.
                              <br />
                              Potrai opporti sin da subito ed in qualsiasi momento a tale trattamento inviandoci una comunicazione ad info@e-leads.it. Rispetto alle comunicazioni commerciali, avrai
                              sempre la possibilità di opporti a tale trattamento sia chiedendo di non ricevere più tali comunicazioni inviando una e-mail ad info@e-leads.it oppure cliccando
                              direttamente sul link di cancellazione (“cancella l’iscrizione”) che troverai in fondo ad ogni e-mail ricevuta. Potrai effettuare l’opt-out dall’attività di remarketing e
                              personalizzazione degli annunci attraverso il seguente {" "}
                              <a href='https://support.google.com/google-ads/answer/2549063?hl=it' target='_blank'>
                                link
                              </a>. In caso di revoca del consenso, il trattamento dei dati per tale finalità cesserà immediatamente.
                            </li>
                            <li>
                              <b>Legittimo interesse</b>
                              <br />
                              Ci avvarremo del legittimo interesse per le finalità di cui alle lettere d. ed e. del paragrafo “FINALITÀ DEL TRATTAMENTO” incluso per rilevare il tuo grado di
                              soddisfazione rispetto ai servizi erogati dal Titolare, dai Partners o rispetto all’utilizzo del Sito. Potrai quindi essere ricontattato telefonicamente oppure tramite
                              posta elettronica, fatto salvo il tuo diritto di opporti a tale trattamento in qualsiasi momento inviandoci una comunicazione ad info@e-leads.it. <br />
                              Analizzeremo il traffico ed il comportamento degli utenti durante la navigazione sul sito in modo da migliorare il nostro servizio e l’esperienza di navigazione dei
                              visitatori. <br />
                              In conformità all’articolo 13 comma 2 della Direttiva 2009/136/CE, nonché in riferimento al Considerando (27) del REGOLAMENTO 2016/679 potremo utilizzare il tuo indirizzo
                              e-mail acquisito nel contesto di una relazione di clientela tra te e il Titolare (ad esempio perché hai utilizzato i nostri servizi e concluso una transazione tramite il
                              sito) per inviarti comunicazioni elettroniche che abbiano ad oggetto prodotti o servizi analoghi a quelli offerti in precedenza. Hai diritto, sin da ora di rifiutare tali
                              comunicazioni in maniera gratuita inviandoci una comunicazione ad info@e-leads.it nonché di opporti in futuro a tale trattamento chiedendo la cancellazione della tua
                              e-mail.
                            </li>
                          </ol>
                        </p>
                      </div>
                    </div>

                    <div className='row mt-3' id='Destinatari'>
                      <div className='col-md-12'>
                        <h4>DESTINATARI O CATEGORIE DI DESTINATARI</h4>
                        <div className=' mt-6'>
                          <h5>Partners</h5>
                          Comunicheremo ai nostri Partners (Banche, Intermediari finanziari, Intermediari iscritti al R.U.I., Agenti in attività finanziaria, Mediatori Creditizi, Compagnie
                          Assicurative, Compagnie Telefoniche, Società di Noleggio a lungo termine, Agenzie di marketing e pubblicità, Società fornitrici di utilities, portali di comparazione di servizi professionali per la casa) con cui abbiamo sottoscritto o
                          sottoscriveremo accordi commerciali, le informazioni necessarie all’erogazione dei servizi da te richiesti. Si tratta di una comunicazione necessaria affinché tu possa essere
                          ricontattato e ricevere le informazioni da te sollecitate. I dati comunicati potranno includere il tuo nome, l’indirizzo e-mail ed i dati di contatto. I Partners che ricevono
                          queste informazioni dovrebbero utilizzarle solo per scopi correlati al servizio e risponderealle tue richieste. Non dovrebbero contattarti per altri scopi incompatibili, a
                          meno non abbia dato il tuo consenso libero, specifico, informato e non ambiguo.
                        </div>

                        <div className=' mt-6'>
                          <h5>Soggetti Terzi appartenenti alle seguenti categorie</h5>
                          Banche, Intermediari finanziari, Intermediari iscritti al R.U.I., Agenti in attività finanziaria, Mediatori Creditizi, Compagnie Assicurative, Compagnie Telefoniche, Società
                          di Noleggio a lungo termine, Agenzie di marketing e pubblicità, Società fornitrici di utilities, portali di comparazione di servizi professionali per la casa (per loro finalità promozionali e, specialmente, per l'invio di materiale
                          pubblicitario e/o di vendita diretta e/o per il compimento di ricerche di mercato e/o di comunicazione commerciale, analisi di mercato, invio di comunicazioni commerciali
                          tramite e-mail, telefono, sms, mms).
                        </div>

                        <div className=' mt-6'>
                          <h5>Fornitori di servizi di hosting.</h5>
                          Il servizio di hosting del sito è gestito da Digital Ocean LLC, New York, Stati Uniti, (“<b>Responsabile del trattamento</b>”). Ai sensi del Regolamento UE 2016/679
                          Regolamento generale sulla protezione dei dati (“il GDPR”) (Articolo 28, paragrafo 3), il Titolare del trattamento è tenuto a stipulare un accordo scritto tra il Titolare e
                          qualsiasi organizzazione che elabora dati personali per suo conto. Abbiamo quindi sottoscritto elettronicamente un addendum sul trattamento dei dati o DPA (Data Processing
                          Agreement) per assicurare la conformità agli obblighi previsti dal regolamento. Per conoscere le modalità di trattamento dei dati effettuato dal responsabile del trattamento,
                          si prega di visitare la privacy policy raggiungibile al seguente link:{" "}
                          <a href='https://www.digitalocean.com/security/gdpr/data-processing-agreement/' target='_blank'>
                            https://www.digitalocean.com/security/gdpr/data-processing-agreement/
                          </a>
                          .<br />
                          Tutti i datacenter utilizzati si trovano in territori UE.
                        </div>
                        <div className=' mt-6'>
                          <h5>Fornitori di servizi di tracciamento ed analisi del traffico web, sistemi automatizzati di segmentazione utenza e marketing.</h5>
                          <ol>
                            <li>
                              Google Inc. con sede legale in 1600 Amphitheatre Parkway. Mountain View, CA 94043 (“<b>Responsabile del trattamento</b>”). Ai sensi del Regolamento UE 2016/679
                              Regolamento generale sulla protezione dei dati (“GDPR”), articolo 28, paragrafo 3, il Titolare del trattamento è tenuto a stipulare un accordo scritto tra il Titolare
                              stesso e qualsiasi organizzazione che elabora dati personali per suo conto. Pertanto, abbiamo stipulato un accordo di elaborazione dei dati con Google Inc. per garantire
                              il rispetto delle suddette disposizioni del GDPR in relazione a tutti i trattamenti dei dati personali effettuati dal Responsabile del trattamento. L’accordo è
                              rinvenibile al seguente link{" "}
                              <a href='https://privacy.google.com/businesses/processorterms/' target='_blank'>
                                https://privacy.google.com/businesses/processorterms/
                              </a>{" "}
                              Per conoscere le modalità di trattamento dei dati effettuato dal Responsabile del trattamento, si prega di visitare la privacy policy raggiungibile al seguente link:{" "}
                              <a href='https://policies.google.com/privacy?hl=policies' target='_blank'>
                                https://policies.google.com/privacy?hl=policies
                              </a>{" "}
                              <br />
                              Puoi chiedere di non essere tracciato da Google Inc disattivando le funzioni di Analytics seguendo le istruzioni ed installando il componente aggiuntivo disponibile qui{" "}
                              <a href='https://tools.google.com/dlpage/gaoptout' target='_blank'>
                                https://tools.google.com/dlpage/gaoptout
                              </a>
                              . <br />
                              Potrai inoltre gestire le impostazioni degli annunci tramite il tuo account Google seguendo le istruzioni qui indicate:{" "}
                              <a href='https://bit.ly/2nsCU7z' target='_blank'>
                                https://bit.ly/2nsCU7z
                              </a>
                            </li>
                            <li>
                              Hotjar Limited con sede legale a Level 2, St Julian’s Business Centre, 3, Elia Zammit Street, St Julian’s STJ 1000, Malta (“Responsabile del trattamento”).
                              <br />
                              Per proteggere la tua privacy, le informazioni che inserisci nei moduli ed il tuo indirizzo IP sono mascherati e resi illeggibili prima che le informazioni vengano
                              trasmesse a Hotjar.
                              <br />
                              Ai sensi del Regolamento UE 2016/679 Regolamento generale sulla protezione dei dati (“GDPR”), articolo 28, paragrafo 3, il Titolare del trattamento è tenuto a stipulare
                              un accordo scritto tra il Titolare stesso e qualsiasi organizzazione che elabora dati personali per suo conto. Pertanto, abbiamo stipulato un accordo di elaborazione dei
                              dati con Hotjar Limited per garantire il rispetto delle suddette disposizioni del GDPR in relazione a tutti i trattamenti dei dati personali effettuati dal Responsabile
                              del trattamento dei dati. L’accordo è rinvenibile al seguente link.{" "}
                              <a href='https://www.hotjar.com/legal/support/dpa' target='_blank'>
                                https://www.hotjar.com/legal/support/dpa
                              </a>
                              <br />
                              Per conoscere le modalità di trattamento dei dati effettuato dal Responsabile del trattamento, si prega di visitare la privacy policy raggiungibile al seguente link:{" "}
                              <a href='https://www.hotjar.com/legal/policies/privacy' target='_blank'>
                                https://www.hotjar.com/legal/policies/privacy
                              </a>{" "}
                              <br />
                              Nonostante le informazioni raccolte siano anonimizzate, l’utente può comunque chiedere di non essere tracciato da Hotjar seguendo le istruzioni indicate al seguente link:{" "}
                              <a href='https://www.hotjar.com/legal/compliance/opt-out' target='_blank'>
                                https://www.hotjar.com/legal/compliance/opt-out
                              </a>{" "}
                              <br />
                            </li>
                            <li>
                              Meta Platforms Ireland Limited con sede legale a 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Dublin, D02x525, Ireland (“Responsabile del trattamento”).
                              <br />
                              Per proteggere la tua privacy, le informazioni che inserisci nei moduli ed il tuo indirizzo IP sono mascherati e resi illeggibili prima che le informazioni vengano
                              trasmesse a Hotjar.
                              <br />
                              Ai sensi del Regolamento UE 2016/679 Regolamento generale sulla protezione dei dati (“GDPR”), articolo 28, paragrafo 3, il Titolare del trattamento è tenuto a stipulare
                              un accordo scritto tra il Titolare stesso e qualsiasi organizzazione che elabora dati personali per suo conto. Pertanto, abbiamo stipulato un accordo di elaborazione dei
                              dati con Meta Platforms Ireland Limited per garantire il rispetto delle suddette disposizioni del GDPR in relazione a tutti i trattamenti dei dati personali effettuati
                              dal Responsabile del trattamento dei dati. L’accordo è rinvenibile al seguente link.{" "}
                              <a href='https://www.facebook.com/legal/terms/dataprocessing' target='_blank'>
                                https://www.facebook.com/legal/terms/dataprocessing
                              </a>
                              <br />
                              Per conoscere le modalità di trattamento dei dati effettuato dal Responsabile del trattamento, si prega di visitare la privacy policy raggiungibile al seguente link:{" "}
                              <a href='https://www.facebook.com/about/privacy/' target='_blank'>
                                https://www.facebook.com/about/privacy/
                              </a>{" "}
                              <br />
                              Nonostante le informazioni raccolte siano anonimizzate, l’utente può comunque chiedere di non essere tracciato da Hotjar seguendo le istruzioni indicate al seguente link:{" "}
                              <a href='https://m.facebook.com/help' target='_blank'>
                                https://m.facebook.com/help
                              </a>{" "}
                              <br />
                            </li>
                          </ol>
                        </div>
                      </div>
                    </div>

                    <div class='row mt-3' id='Categorie'>
                      <div class='col-md-12'>
                        <h4>CATEGORIE DI DATI PERSONALI TRATTATI</h4>
                        <p class=' mt-6'>
                          <ol>
                            <li>
                              I sistemi informatici e le procedure software preposte al funzionamento di questo sito web potrebbero raccogliere automaticamente alcune informazioni quali indirizzi IP
                              ed altri dati la cui trasmissione è implicita nell’uso dei protocolli di comunicazione di Internet. Tali dati, benché non utilizzati per identificare gli utenti, ben
                              potrebbero per via delle loro caratteristiche e se associati con altri dati, permettere di identificare gli utenti, (ad es. indirizzo IP, nomi a dominio dei computer
                              utilizzati dagli utenti che si collegano al sito, ecc.). Questi dati possono essere utilizzati sia ricavare informazioni di tipo statistico in forma aggregata, sia per
                              controllare il corretto funzionamento del sito.
                            </li>
                            <li>
                              Per poter procedere con la prestazione del servizio, l’utente dovrà conferire una serie di dati personali, quali a titolo esemplificativo e non esaustivo: nome, cognome,
                              età, indirizzo di posta elettronica, indirizzo di residenza.
                            </li>
                            <li>
                              Tramite terze parti potremmo raccogliere posizione geografica, tipo di browser utilizzato, numero di visualizzazioni delle pagine, indirizzo IP, informazioni sui click
                              effettuati sull e pagine del Sito. Questi dati possono essere utilizzati sia per ricavare informazioni di tipo statistico in forma aggregata, sia per migliorare
                              l’esperienza dell’utente durante la navigazione sul sito.
                            </li>
                          </ol>
                        </p>
                      </div>
                    </div>

                    {/*  <div class="row mt-3" id="Trasferimento" >
                    <div class="col-md-12">
                      <h4>TRASFERIMENTO DEI DATI IN PAESI TERZI</h4>
                      <p class=" mt-6">
                        I dati personali degli interessati potranno essere trasferiti nel seguente Paese: Stati Uniti d’America.
                  Tutti i destinatari dei dati stabiliti negli Stati Uniti che riceveranno i dati personali hanno confermato il rispetto del Quadro regolamentare EU-U.S. Privacy Shield. Il trasferimento è autorizzato dalla seguente decisione di adeguatezza approvata dalla Commissione Europea: “<b>Privacy Shield</b>”.

                  </p>
                    </div>
                  </div> */}

                    <div class='row mt-3' id='Conservazione'>
                      <div class='col-md-12'>
                        <h4>CRITERI PER LA CONSERVAZIONE DEI DATI</h4>
                        <p class=' mt-6'>
                          I tuoi dati personali verranno conservati per il tempo necessario al raggiungimento delle finalità indicate in questa informativa e quindi solo per il tempo strettamente
                          necessario a fornire il servizio previsto dal contratto o a fornirti le informazioni richieste. I dati saranno pertanto utilizzati fino al raggiungimento delle finalità e
                          successivamente cancellati. Saranno inoltre cancellati non appena ci chiederai di farlo oppure quando il trattamento di basa sul tuo consenso, non appena deciderai di
                          revocarlo sia tramite i link di opt-out presenti nelle comunicazioni commerciali ricevute, sia inviandoci una comunicazione al seguente indirizzo info@e-leads.it.
                        </p>
                      </div>
                    </div>

                    <div class='row mt-3' id='Diritti'>
                      <div class='col-md-12'>
                        <h4>DIRITTI DELL’INTERESSATO</h4>
                        <p class=' mt-6'>In qualità di interessato al trattamento, hai il diritto di:</p>
                        <ol>
                          <li>essere informato rispetto all’esistenza o meno di dati personali che ti riguardano;</li>
                          <li>accedere ai dati personali che sono oggetto di trattamento;</li>
                          <li>chiedere la rettifica nel caso in cui i dati che abbiamo raccolto siano inesatti o chiedere l’integrazione dei dati nel caso in cui gli stessi siano incompleti;</li>
                          <li>
                            chiedere al Titolare la cancellazione dei dati nei casi previsti dall’articolo 17 del Regolamento 2016/679, tra cui in caso di revoca del consenso o se i dati personali
                            trattati non sono più necessari rispetto alle finalità per le quali sono stati raccolti o altrimenti trattati;
                          </li>
                          <li>ottenere la limitazione del trattamento ai sensi dell’articolo 18 del Regolamento 2016/679;</li>
                          <li>
                            richiedere al Titolare la portabilità dei tuoi dati personali e di riceverli in un formato strutturato, di uso comune e leggibile o di ottenere la trasmissione diretta dei
                            tuoi dati personali ad un altro titolare;
                          </li>
                          <li>di opporti in qualsiasi momento al trattamento dei tuoi dati;</li>
                          <li>
                            non essere sottoposto a una decisione basata unicamente sul trattamento automatizzato, compresa la profilazione, che produca effetti giuridici che ti riguardano o che
                            incida in modo analogo significativamente sulla tua persona.
                          </li>
                          <li>di proporre reclamo al Garante per la protezione dei dati personali.</li>
                        </ol>
                        <h4 class=' mt-8'>COME ESERCITARE I TUOI DIRITTI?</h4>
                        <p class=' mt-6'>
                          Puoi esercitare i tuoi diritti in qualsiasi momento inviandoci un messaggio di posta elettronica al seguente indirizzo: info@e-leads.it.
                          <br />
                          Abbiamo il dovere di rispondere alle tue richieste entro un mese dal loro ricevimento. Tale termine può essere prorogato di due mesi, se necessario, tenuto conto della
                          complessità e del numero delle richieste ricevute. In caso di proroga verrai informato del ritardo e dei motivi.
                          <br />
                          Nell’ipotesi in cui ritenessimo di non poter dar seguito alle tue richieste, ti comunicheremo i motivi del rifiuto. In tal caso avrai comunque la possibilità di proporre
                          reclamo all’Autorità Garante per la protezione dei dati personali.
                          <br />
                          <br />
                          La presente informativa potrebbe subire modifiche. Si invitano i visitatori a verificare regolarmente il testo della privacy policy.
                        </p>
                      </div>
                    </div>

                    <div class='row text-center'>
                      <div class='col-md-12'>
                        <h2>INFORMATIVA COOKIES</h2>
                      </div>
                    </div>

                    <div class='row mt-8'>
                      <div class='col-md-12'>
                        <h4>Cosa sono i cookies?</h4>
                        <p class=' mt-6'>
                          I cookies sono piccoli file di testo che i siti visitati dagli utenti inviano ai loro terminali, ove vengono memorizzati per essere poi ritrasmessi agli stessi siti alla
                          visita successiva. I cookie possono sono utilizzati per differenti finalità: esecuzione di autenticazioni informatiche, monitoraggio di sessioni, memorizzazione di
                          informazioni su specifiche configurazioni riguardanti gli utenti che accedono al server, memorizzazione delle preferenze. Il sito utilizza cookie tecnici, analitici e di
                          profilazione.
                        </p>
                      </div>
                    </div>

                    <div class='row mt-8'>
                      <div class='col-md-12'>
                        <h4>Cookies tecnici</h4>
                        <p class=' mt-6'>
                          Il sito utilizza cookies necessari a permettere agli utenti la corretta navigazione sul sito, consentire l’uso dei servizi interattivi, facilitare e migliorare l'esperienza
                          degli utenti sul nostro sito. Alcune funzioni del nostro sito dipendono dai cookie tecnici per poter funzionare correttamente. All’interno di questa categoria rientrano anche
                          i cookies di funzionalità, che permettono all’utente la navigazione in funzione di una serie di criteri selezionati (a titolo esemplificativo, la lingua, i prodotti
                          selezionati per l’acquisto) al fine di migliorare il servizio che ti viene reso. Per questa categoria di cookies non ti verrà richiesto il consenso, fermo restando che potrai
                          comunque disabilitarli in qualsiasi momento tramite le impostazioni del tuo browser. Disabilitando i cookies tecnici non potremo garantirti un utilizzo del sito privo di
                          errori
                        </p>
                      </div>
                    </div>

                    <div class='row mt-8'>
                      <div class='col-md-12'>
                        <h4>Cookies Analytics</h4>
                        <p class=' mt-6'>
                          Il sito utilizza anche cookies di Google Analytics per raccogliere informazioni in forma aggregata sul numero degli utenti e su come gli utenti utilizzano il sito. Si tratta
                          di un servizio di analisi web fornito da Google. Le informazioni generate sono utilizzate per indicare le tendenze degli utenti su questo sito web senza identificare i
                          singoli visitatori e per fare dei report sull'utilizzo del sito stesso. I dati generati da Google Analytics sono conservati da Google così come indicato nella informativa
                          reperibile al seguente link{" "}
                          <a href='https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage' target='_blank'>
                            https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage
                          </a>
                          . Per consultare l’informativa privacy della società Google Inc., si rinvia al sito internet{" "}
                          <a href='http://www.google.com/intl/en/analytics/privacyoverview.html' target='_blank'>
                            http://www.google.com/intl/en/analytics/privacyoverview.html
                          </a>
                          . Il Titolare ha accettato i “Termini contrattuali per il trattamento dei dati degli Annunci di Google” messo a disposizione da Google Inc nel rispetto del Regolamento
                          2016/679 e che definiscono alcuni aspetti sull’elaborazione e la sicurezza dei dati personali degli utenti in conformità alla normativa sulla protezione dei dati. Gli utenti
                          che non desiderano che i propri dati vengano utilizzati da Google Analytics, possono installare{" "}
                          <a href='https://tools.google.com/dlpage/gaoptout' target='_blank'>
                            il componente aggiuntivo del browser per la disattivazione di Google Analytics
                          </a>
                          . Questo componente aggiuntivo indica al JavaScript di Google Analytics (ga.js, analytics.js, and dc.js) su siti web di non utilizzare i dati di Google Analytics.
                        </p>
                      </div>
                    </div>

                    <div class='row mt-8'>
                      <div class='col-md-12'>
                        <h4>Cookies di profilazione, marketing, pubblicitari e di tracciamento</h4>
                        <p class=' mt-6'>
                          I cookies di profilazione sono utilizzati per ricevere offerte costruite sulle preferenze di acquisto ed i gusti manifestati dagli utenti durante la navigazione online e
                          fargli così visualizzare messaggi pubblicitari coerenti con il suo profilo. In questo modo i messaggi pubblicitari che visualizzerà sul sito potranno essere più in linea con
                          le sue preferenze. I cookies di profilazione comprendono anche quelli idonei a tracciare le scelte dell’utente durante la navigazione. L’utente dovrà acconsentire
                          all’installazione dei cookies di tracciamento e sarà comunque libero di bloccare l'installazione dei cookies di profilazione in qualsiasi momento, senza che ciò comporti
                          alcuna conseguenza sulla possibilità di visitare il sito e fruire dei suoi contenuti. Se l’utente decide di disattivare i cookies di profilazione i banner pubblicitari e le
                          inserzioni eventualmente visualizzati sui siti potrebbero non rispecchiare gli interessi o le preferenze dell’utente.
                          <br />
                          <br />
                          Segue un elenco dei cookies di remarketing, analytics e tracciamento installati tramite il sito:
                          <div id='cookies'></div>
                        </p>
                      </div>
                    </div>

                    <div class='row mt-8'>
                      <div class='col-md-12'>
                        <h4>Come si abilitano o disabilitano i cookies tramite il browser?</h4>
                        <p class=' mt-6'>
                          In caso l’utente decida di non permettere l’installazione dei cookies di navigazione o tecnici sul computer, alcune funzioni e servizi potrebbero non essere disponibili. I
                          cookie tecnici non registrano alcuna informazione personale su un utente e gli eventuali dati identificabili non verranno memorizzati. Se l’utente decide di optare per il
                          blocco della memorizzazione dei cookie tecnici, il Titolare non potrà più garantire un corretto funzionamento del sito. Fermo restando quanto indicato in ordine ai cookie
                          necessari alla navigazione, l’utente può impedire l’installazione dei cookies analytics, di marketing e tracciamento tramite le impostazioni del banner o eliminarli
                          successivamente tramite il proprio browser. Ciascun browser Web consente limitare ed eliminare i cookie attraverso le impostazioni. Per maggiori informazioni sulla gestione
                          dei cookie, consultare il link pertinente indicato di seguito.
                          <br />
                          <a href='https://privacy.microsoft.com/en-us/privacystatement' target='_blank'>
                            Internet Explorer
                          </a>
                          <br />
                          <a href='https://support.mozilla.org/it/kb/Gestione%20dei%20cookie?redirectlocale=en-US&redirectslug=Cookies' target='_blank'>
                            Firefox
                          </a>
                          <br />
                          <a href='https://support.google.com/chrome/answer/95647?hl=it' target='_blank'>
                            Chrome
                          </a>
                          <br />
                          <a href='https://support.apple.com/kb/PH5042?locale=en_US' target='_blank'>
                            Safari
                          </a>
                          <br />
                          Il Titolare informa che è possibile utilizzare il servizio Your Online Choices e modificare le impostazioni dei cookies in base alle tue preferenze{" "}
                          <a href='http://www.youronlinechoices.com/it ' target='_blank'>
                            http://www.youronlinechoices.com/it{" "}
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='row mt-8'>
                  <div class='col-md-12 text-right'>
                    <p class=' mt-6'>
                      <i>Ultimo aggiornamento: [20 Gennaio 2022]</i>
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </Layout>
      </Page>
    );
  }
}
